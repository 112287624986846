<template>
	<div id="terms">
		<div id="terms-inner">
			<h2>Ehdot & Edellytykset</h2>
			<p>
				Kiitämme, kun valitsit CustomBrandingPRO:n graafisiin
				suunnittelutarpeisiisi.
			</p>
			<p>
				Ennen kuin jatkamme, ota hetki tarkastella tämä sopimus
				huolellisesti – se on tärkeää!
			</p>
			<p>
				<strong>Palvelumme:</strong> Olemme täällä tarjoamassa kattavia
				graafisen suunnittelun palveluita, kattaen kaiken
				brändisuunnittelusta logon uudistamiseen ja PowerPoint-esitysten
				"siistimiseen". Hoidamme graafiset suunnittelutarpeesi!
			</p>
			<p>
				<strong>Maksuprosessi:</strong> Aloittaaksesi täytä Briefing
				Sheet -lomake. Huomautuksena, jos palaat siihen ennen maksun
				viimeistelyä, joudut aloittamaan alusta. Lisäksi tehtävät, jotka
				ovat suunnittelun ulkopuolella, kuten IT, verkkotunnusten
				hankinta, tulostus tai materiaalikustannukset, voivat aiheuttaa
				lisäkustannuksia.
			</p>
			<p>
				<strong>Seuraavat vaiheet</strong> Saitko kolme brändikonseptia
				ja haluat enemmän? Helppoa! Vastaa sähköpostiimme, kerro
				valintasi paketista, ja lähetämme sinulle laskun vähintään 50
				%:n etumaksulla. Basic Artwork Package, 390 €, laskutetaan
				kokonaan ennen tehtävän aloittamista.
			</p>
			<p>
				<strong>Lakisääteiset tiedot:</strong> Sopimalla sitoudut
				noudattamaan yleisiä tekijänoikeuslakeja. Huomaa, että
				CustomBrandingPRO ja Nina A. Ignatius eivät käsittele fonttien,
				kuvien, logojen tai muiden elementtien lisenssejä tai
				käyttöoikeuksia. Et myöskään saa myydä suunnitelmiamme
				kolmansille osapuolille, vaikka et päättäisikään käyttää niitä.
				Suostumuksesi antaa myös CustomBrandingPRO:lle mahdollisuuden
				käyttää suunnitelmiamme ja logojasi mahdollisesti
				markkinointitarkoituksiin.
			</p>
			<p>
				<strong>Vahvistus:</strong> Ruksaa laatikko vahvistaaksesi, että
				olet lukenut ja ymmärtänyt ehdot.
			</p>
			<p>
				OLE HYVÄ JA HUOMIOI, ETTÄ KUN OLET VAHVISTANUT JA HYVÄKSYNYT
				EHDOT, SINUT OHJATAAN MAKSUSIVULLE. TÄMÄN JÄLKEEN ET VOI PALATA
				TÄLLE SIVULLE TÄYTETYN LOMAKKEEN KANSSA, MIKÄ MITÄYTÖI
				TILAUKSESI, JA SINUN TÄYTYY ALOITTAA ALUSTA.
			</p>
			<p>
				<strong>Kysymyksiä tai huolia:</strong>Jos sinulla on
				kysyttävää, ota yhteyttä osoitteeseen
				<a href="mailto:nina@custombrandingpro.com"
					>nina@custombrandingpro.com</a
				>.
			</p>
			<p>Kiitos, että valitsit CustomBrandingPRO:n!</p>
			<p>
				CustomBrandingPRO on palvelu, jonka tarjoaa Beibamboo Oy/Ltd,
				ALV-numero FI22833580. Arvostamme ymmärtämystäsi ja
				yhteistyötäsi.
			</p>
			<p>Ystävällisin terveisin, CustomBrandingPRO-tiimi</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TermsFi'
	};
</script>

<style scoped>
	#terms {
		margin: 0 auto;
		margin-top: 10vh;
		max-width: 80%;
		padding: 10px;
	}

	#terms-inner {
		padding-top: 80px;
	}

	#terms h2,
	p {
		max-width: 100%;
		text-align: center;
		padding: 5px;
	}
</style>
